import { css, SerializedStyles } from "@emotion/react";
import { defaultMuiTheme } from "./defaultMuiTheme";

type TwisterProps = {
  angle?: number;
};

type LineClampProps = {
  lines?: number;
};

type ToggleInProps = {
  duration?: number;
};

const alertBarHeightMobile = "60px";
const alertBarHeightDesktop = "45px";
const progressBarHeight = "4px";
const appHeaderHeightMobile = "56px";
const appHeaderHeightDesktop = "64px";

export const cssMixins = {
  alertBarHeight: css`
    height: ${alertBarHeightMobile};
    @media (min-width: 960px) {
      height: ${alertBarHeightDesktop};
    }
  `,
  alertBarHeightDesktop,
  alertBarHeightMobile,
  alertBarTop: css`
    top: 60px;
    @media (min-width: 960px) {
      top: 45px;
    }
  `,
  appHeaderHeight: css`
    height: ${appHeaderHeightMobile};
    @media (min-width: 600px) {
      height: ${appHeaderHeightDesktop};
    }
  `,
  appHeaderHeightDesktop,
  appHeaderHeightMobile,
  backgroundFull: css`
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  `,
  colCentered: css`
    flex-direction: column;
    align-items: center;
    display: flex;
    justify-content: center;
  `,
  colHCentered: css`
    flex-direction: column;
    display: flex;
    justify-content: center;
  `,
  colStretch: css`
    display: flex;
    flex-direction: column;
  `,
  colVCentered: css`
    flex-direction: column;
    align-items: center;
    display: flex;
  `,

  containerFullHeightInner: css`
    height: 100%;
    overflow-y: auto;
  `,

  containerFullHeightOuter: css`
    height: 100%;
  `,

  containerMaxWidth: "1500px",
  diffIconStyle: css``,
  enableTextSelect: css`
    -webkit-touch-callout: auto; /* iOS Safari */
    -webkit-user-select: auto; /* Safari */
    -khtml-user-select: auto; /* Konqueror HTML */
    -moz-user-select: auto; /* Old versions of Firefox */
    -ms-user-select: auto; /* Internet Explorer/Edge */
    user-select: auto; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  `,
  global: css`
    html,
    body {
      margin: 0;
      padding: 0;
    }
  `,
  headerMenuWidth: css``,
  heroImgLinearGradient: css`
    background: linear-gradient(
      43deg,
      rgba(46, 56, 97, 0.8) 0%,
      rgba(77, 108, 155, 0.75) 84%,
      rgba(84, 121, 170, 0.74) 100%
    );
  `,
  heroLinearGradient: css`
    background: linear-gradient(
      42deg,
      rgba(46, 56, 98, 0.94) 0%,
      rgba(78, 108, 156, 0.94) 85%,
      rgba(84, 120, 169, 0.93) 100%
    );
  `,
  heroSecondaryButtonBackground: css`
    background-color: rgba(233, 91, 120, 0.05);
  `,
  heroSecondaryButtonBackgroundHover: css`
    background-color: rgba(233, 91, 120, 0.1);
  `,
  linearGradientText: css``,
  lineClamp: ({ lines = 1 }: LineClampProps): SerializedStyles => css`
    display: block;
    display: -webkit-box;
    -webkit-line-clamp: ${lines};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  mainCTAButtonHeight: "70px",
  mediaPromotionBackground: css``,
  moveDownAnimation: ({
    duration = 300,
  }: ToggleInProps): SerializedStyles => css`
    @keyframes moveDown {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0%);
      }
    }
    animation: moveDown ${duration}ms 1;
  `,
  noSelect: css`
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
  `,
  processCardShadowHeader: css``,
  progressBarHeight,
  respTextAlign: css`
    text-align: center;
    ${defaultMuiTheme.breakpoints.up("md")} {
      text-align: left;
    }
  `,
  rowCentered: css`
    align-items: center;
    display: flex;
    justify-content: center;
  `,
  rowHCentered: css`
    display: flex;
    justify-content: center;
  `,
  rowStretch: css`
    display: flex;
  `,
  rowVCentered: css`
    align-items: center;
    display: flex;
  `,
  sceneSpacingV: css`
    padding-top: ${defaultMuiTheme.spacing(3)};
    padding-bottom: ${defaultMuiTheme.spacing(3)};
  `,
  scrollbarHidden: css`
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  `,
  sectionHeading: css`
    ${defaultMuiTheme.breakpoints.up("sm")} {
      max-width: 75%;
    }
    ${defaultMuiTheme.breakpoints.up("lg")} {
      max-width: 50%;
    }
  `,
  servicesCardsFilter: css``,
  servicesCardsHoverFilter: css``,
  spaceSimpleV: (spacing: number): SerializedStyles => css`
    margin-bottom: ${defaultMuiTheme.spacing(spacing)};

    &:last-child {
      margin-bottom: 0;
    }

    + & {
      margin-top: ${defaultMuiTheme.spacing(spacing)};
    }
  `,
  stretchAll: css`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  stretchBottom: css`
    right: 0;
    bottom: 0;
    left: 0;
  `,
  stretchTop: css`
    top: 0;
    right: 0;
    left: 0;
  `,
  toggleInAnimation: ({
    duration = 300,
  }: ToggleInProps): SerializedStyles => css`
    @keyframes toggleIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    animation: toggleIn ${duration}ms 1;
  `,
  transitionalCTABgFilter: css``,
  twister: ({ angle = -180 }: TwisterProps): SerializedStyles => css`
    transform: rotate(${angle}deg);
    transition: ${defaultMuiTheme.transitions.create("transform")};
  `,
  verticalText: css`
    writing-mode: vertical-rl;
    -webkit-writing-mode: tb-rl;
    -ms-writing-mode: tb-rl;
    transform: scale(-1);
  `,
};
